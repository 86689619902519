import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import HeadShot from '../assets/head_shot.png';


//Local stuff.
import "../styles/main.css";
import Navigation from "../components/Navigation";
import Resume from '../assets/files/Resume_BruceConway.pdf';

function Contact() {
  let history = useHistory();

  useEffect(() => {
    // For some strange reason we can't execute the window scroll right away.
    // Fix was to wait 100 milliseconds, not ideal but it works...
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  }, [history]);

  return (
    <div className="about">
      <div className="half-background"></div>
      <div className="horizontally-center-page">
        <div className="main-width">
          <Navigation />

          <div className="center">
            <h1 className="header-text black-text heavy-font">
              Resume
              <span className="period">.</span>
            </h1>
          </div>

          <div className="center">
            <div className='head-shot-container' style={{ marginTop: '0px', borderRadius: '100%' }}>
              <img
                id='hsc'
                className='head-shot'
                style={{ borderRadius: '50%' }}
                src={HeadShot}
                alt='Alexander Stewart head shot'
              />
            </div>
          </div>

          <div className='flex-content-a-container'>
            <div className='flex-content-a'>
              <p className='blurb text-align-left black-text light-font'>
                <a href={Resume} download='Resume_BruceConway.pdf'>
                  Here is a link to my resume
                </a>
                . I am currently seeking employment or contract positions (part-time or full-time) specializing in Enterprise Lease Management. Be sure to check out my{" "}
                <a href='https://www.linkedin.com/in/bruceconway/'>
                  LinkedIn
                </a>
                {" "}for references.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;