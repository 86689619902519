import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import { useSpring, animated } from 'react-spring';

//Local stuff.
import '../styles/main.css';
import '../styles/lines.css';
import '../styles/box.css';
import ConwayFamily from '../assets/conway_family.jpg';
import NavigationHome from '../components/NavigationHome';
import Box from '../components/Box';
import { Spinner } from '@chakra-ui/react';

function Home() {
  let history = useHistory();
  const [opacity, api] = useSpring(() => ({
    opacity: 1,
  }));

  useEffect(() => {
    // For some strange reason we can't execute the window scroll right away.
    // Fix was to wait 100 milliseconds, not ideal but it works...
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 100);
  }, [history]);

  return (
    <div>
      <animated.div className='big-white' style={opacity}>
        <Spinner
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            width: 80,
            height: 80,
            lineHeight: 80,
            marginTop: -40,
            marginLeft: -40,
          }}
        />
      </animated.div>
      <div className='home'>
        <div className='canvas-container'>
          <Canvas
            className='canvas'
            linear
            camera={{ position: [0, 0, 10], near: 2, far: 20, zoom: 10 }}
            gl={{ antialias: true, alpha: false }}
            onCreated={(state) => {
              api.start({ opacity: 0, config: { duration: 900 } });
            }}
          >
            <color attach='background' args={['#f5efdf']} />
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <Box position={[0, 0, 0]} />
          </Canvas>
        </div>
        <div className='line-a'></div>
        <div className='line-b'></div>
        <div className='line-c'></div>
        <div className='line-d'></div>
        <div className='line-e'></div>
        <div className='line-f'></div>
        <div className='line-g'></div>
        <div className='line-h'></div>
        <div className='half-background'></div>
        <div className='center-page nudge-up'>
          <div className='main-width'>
            <NavigationHome />
            <div className='flex-content-a-container'>
              <div className='flex-content-a'>
                <h1 className='header-text black-text heavy-font'>
                  Hi, I'm<br></br>Bruce<br></br>Conway
                  <span className='period'>.</span>
                </h1>

                <div className='head-shot-container'>
                  <img
                    className='head-shot'
                    src={ConwayFamily}
                    alt='Conway Family'
                  />
                </div>
              </div>
            </div>

            <p className='blurb black-text light-font'>
              I'm a Chartered Professional Accountant with over 22 years
              experience in managing lease portfolios for many fortune 1000
              Companies. <Link to='/about'>Learn more about me.</Link>
            </p>

            <p className='blurb-b black-text light-font text-align-right'>
              <Link to='/resume'>
                Resume
              </Link>
              <br></br>
              <a href='https://www.linkedin.com/in/bruceconway/'>LinkedIn</a>
              <br></br>
              <a href='https://www.instagram.com/bruce.conway/'>Instagram</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
