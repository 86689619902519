import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

//Local stuff.
import '../styles/main.css'
import NavigationAbout from '../components/NavigationAbout'

function Home() {
  let history = useHistory()

  useEffect(() => {
    // For some strange reason we can't execute the window scroll right away.
    // Fix was to wait 100 milliseconds, not ideal but it works...
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 100)
  }, [history])

  return (
    <div className='about'>
      <div className='half-background'></div>
      <div className='horizontally-center-page'>
        <div className='main-width'>
          <NavigationAbout />

          <div className='center'>
            <h1 className='header-text black-text heavy-font'>
              About me
              <span className='period'>.</span>
            </h1>
          </div>

          <div className='flex-content-a-container mt-5'>
            <div className='flex-content-a'>
              <p className='blurb text-align-left black-text light-font'>
                Bruce is a Chartered Professional Accountant with over 23 years experience in managing all aspects of lease portfolios for large organizations.  Bruce started with LeaseAccelerator when it started over 20 years ago, and has held several management positions and is currently Vice-President of Lease Accounting.
                <br></br>
                <br></br>
                Since 2017, Bruce’s focus has been a subject matter expert heavily involved with financial software implementations for dozens of fortune 2000 Companies implementing solutions to comply with ASC 842 and IFRS 16 lease accounting requirements.  Bruce has been heavily involved with accounting, systems, operations and taxes relating to transition accounting, data migration and mapping projects, system replacements, taxes, and all advanced accounting topics related to lease accounting including large enterprise level foreign exchange accounting, inter-company, intra-company allocations, modifications, impairments, change in estimates, payment adjustments, lease and non-lease components, and end-of-term compliance.  Bruce has also been involved with assisting customers with their Audits, and implementation of best practices relating to workflow and segregation of duties, with a focus on minimizing costs, with and independent perspective on the economic benefits of leasing.
                <br></br>
                <br></br>
                Prior to his days focused on Enterprise Lease Management and Leasing software, Bruce held a number of Controllership roles and had a successful career with KPMG and Price Waterhouse.  He received two diplomas from McGill University (Bachelor of Commerce and Graduate Certificate in Professional Accounting). He regularly volunteers with managing sports teams, and enjoys the outdoor ice and other outdoor activities with his wife and two adult children.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
