import React from 'react'
import { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'


//Pages.
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Resume from './pages/Resume'

const App = () => {
  useEffect(() => {
    // For some strange reason we can't execute the window scroll right away.
    // Fix was to wait 100 milliseconds, not ideal but it works...
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 100)
  })

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/about' component={About} />
          <Route path='/contact' component={Contact} />
          <Route path='/resume' component={Resume} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
