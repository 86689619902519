import * as THREE from "three"
import React, { useRef, useMemo } from "react"
import niceColors from "nice-color-palettes"
import { useFrame } from "@react-three/fiber"

// let renderer = new THREE.WebGL1Renderer({ alpha: true });
// renderer.setClearColor(0x000000, 0);

const tempObject = new THREE.Object3D()
const tempColor = new THREE.Color()
const data = Array.from({ length: 1000 }, () => ({
  color: niceColors[17][Math.floor(Math.random() * 5)],
  scale: 1,
}))

function Box(props) {
  const colorArray = useMemo(
    () =>
      Float32Array.from(
        new Array(1000)
          .fill()
          .flatMap((_, i) => tempColor.set(data[i].color).toArray())
      ),
    []
  )
  const meshRef = useRef()

  useFrame((state) => {
    const time = state.clock.getElapsedTime()

    // meshRef.current.rotation.x = Math.sin(time / 4)
    // meshRef.current.rotation.y = Math.sin(time / 2)

    let i = 0
    let y = 0
    let z = 0
    for (let x = 0; x < 3; x++) {
      const id = i++
      tempObject.position.set(-1 + x, y, z)
      tempObject.rotation.y =
        Math.sin(x / 4 + time) +
        Math.sin(y / 4 + time) +
        Math.sin(z / 4 + time)
      tempObject.rotation.z = tempObject.rotation.y * 2
      tempObject.updateMatrix()
      meshRef.current.setMatrixAt(id, tempObject.matrix)
    }

    meshRef.current.instanceMatrix.needsUpdate = true
  })

  return (
    <instancedMesh ref={meshRef} args={[null, null, 10]}>
      <boxBufferGeometry args={[0.6, 0.6, 0.6]}>
        <instancedBufferAttribute
          attachObject={["attributes", "color"]}
          args={[colorArray, 3]}
        />
      </boxBufferGeometry>
      <meshPhongMaterial vertexColors={THREE.VertexColors} />
    </instancedMesh>
  )
}

export default Box
